<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
const store = useStore();


const screens = ref([
  {
    display: "No Error",
    value: "",
  },
  {
    display: "Generic Error",
    value: "generic",
  },
  {
    display: "iOS Camera Denied",
    value: "iosCameraRejected"
  },
  {
    display: "iOS Incompatible Browser Error",
    value: "iosIncompatibleBrowser",
  },
  {
    display: "iOS 13+ Motion Sensors Denied",
    value: "iosMotionDenied13",
  },
  {
    display: "iOS 12.2-12.4 Motion Sensors Denied",
    value: "iosMotionDenied122",
  },
  {
    display: "iOS Upgrade OS",
    value: "iosUpgrade",
  },
  {
    display: "iOS Upgrade OS 11.2",
    value: "iosUpgrade112",
  },
  {
    display: "Android Camera Denied",
    value: "androidCameraDenied",
  },
  {
    display: "Android Camera Denied Samsung",
    value: "androidCameraDeniedSamsung",
  },
  {
    display: "Android Incompatible Browser Error",
    value: "androidIncompatibleBrowser",
  },
  {
    display: "Camera Auto Rejected",
    value: "cameraAutoReject",
  },
]);

const setErrorScreen = (value) => {
  store.commit("setErrorScreenOverride", value);
  store.commit("setAppFailed", value !== "");
};
</script>

<template>
  <div class="module-container">
    <div class="debug-screen-area">
      <div
        class="screen-button"
        v-for="screen in screens"
        v-bind:key="screen"
        v-on:click="setErrorScreen(screen.value)"
      >
        <div>
          {{ screen.display }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../sass/UtilityClasses';
@import '../../sass/Colors';
@import '../../sass/Settings';


.module-container {
  @extend %absolute-fill;

  .debug-screen-area {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;

    background-color: rgba(0, 0, 0, 0.3);

    padding-top: 4vw;
    padding-bottom: 4vw;

    .screen-button {
      font-size: 5vw;
      color: black;

      margin-bottom: 3vw;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 6vw;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding-left: 2vw;
      padding-right: 2vw;

      position: relative;

      background-color: white;
      pointer-events: auto;
    }
  }
}
</style>
