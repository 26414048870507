<script setup>
  import { inject } from 'vue'
  const translate = inject('translate')
</script>

<template>
  <div class="module-container fail-desktop absolute-fill">

    <img class="desktop-box" src="../../../public/assets/imgs/desktop-box.png"/>

    <div class="large-vertical-group">
      <div class="desktop-header" v-html="translate('error-screens-desktop-error-header-1')">
      </div>
      <div class="desktop-body" v-html="translate('error-screens-desktop-error-header-2')">
      </div>
      <div class="desktop-qr-info" v-html="translate('error-screens-desktop-error-header-3')">
      </div>
      <img class="qr-code" src="../../../public/assets/imgs/qr-code.png"/>
    </div>

  </div>
</template>

<style scoped lang="scss">
  @import "../../sass/Settings";

  .fail-desktop
  {

    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;

    .desktop-box {
      position: absolute;
      left: 0;
      bottom: 0;
      width: vh(670, $design-height-desktop);
    }


    .large-vertical-group {
      display: flex;
      flex-direction: column;

      position: absolute;
      right: vw(100, $design-width-desktop);

      width: vh(558, $design-height-desktop);

      .desktop-header {
        margin-bottom: vh(19, $design-height-desktop);
        font-family: Bogle;
        font-size: vh(56, $design-height-desktop);
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: vh(0.56, $design-height-desktop);
        text-align: left;
        color: #fff;
      }

      .desktop-body {
        font-family: Bogle;
        font-size: vh(24, $design-height-desktop);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        margin-bottom: vh(14, $design-height-desktop);
      }
      .desktop-qr-info {
        margin-bottom: vh(23, $design-height-desktop);
        font-family: Bogle;
        font-size: vh(12, $design-height-desktop);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: vh(0.12, $design-height-desktop);
        text-align: left;
        color: #fff;
        width: vh(350, $design-height-desktop);
      }
      .qr-code {
        width: vh(170, $design-height-desktop);
        height: vh(170, $design-height-desktop);
      }
    }

  }
</style>
