import qs from "qs";

export const getQueryParam = (name = "") => {
  if (location.search) {
    const key = name.toLowerCase();
    const params = qs.parse(location.search.toLowerCase().slice(1));
    return params[key] ? params[key] : "";
  } else {
    return "";
  }
};

export default {

  isDebugEnabled: false,

  //-----LANGUAGE-----
  isLanguageSelectorEnlarged: false,
  locale: getQueryParam("locale") || "en-US",

  //-----SCENE CHANGING-----
  isNewSceneLoading: false,

  //-----SCREEN DIMENSIONS-----
  trueVW: 0,
  trueVH: 0,
  designWidthMobile: 375,
  designHeightMobile: 674,
  designWidthDesktop: 1280,
  designHeightDesktop: 800,
};
