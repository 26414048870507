<script setup>

import {ref, defineEmits, inject} from 'vue'
import UncopyableImage from "@/components/UncopyableImage.vue";

const translate = inject('translate')

const props = defineProps({
  instructions: { default: [
      {
        title: "step1-title",
        desc: "step1-copy",
        src: "./assets/imgs/instructions-image-1.png"
      },
      {
        title: "step2-title",
        desc: "step2-copy",
        src: "./assets/imgs/instructions-image-2.png"
      },
      {
        title: "step3-title",
        desc: "step3-copy",
        src: "./assets/imgs/instructions-image-3.png"
      }
    ], type: Array },
  loaded: {default: false, type: Boolean}
});

</script>

<template>
  <div class="InstructionsWrapper absolute-fill">
    <img src="../../public/assets/imgs/ribbon-top.png" class="ribbon ribbon-top"/>
    <img src="../../public/assets/imgs/ribbon-bottom.png" class="ribbon ribbon-bottom"/>
    <div class = "title-area">
      <img src="../../public/assets/imgs/button-back.svg" class="back-button" @click.once="$emit('close')"/>
      <div class = "header-text" v-html="translate('Instructions-title')"></div>
    </div>

    <div class = "instruction-list">
      <div class = "instruction-item" v-for = "item in instructions" v-bind:key="item.title">
        <div class="display-image-hider">
          <img class = "display-image" :src = "item.src" />
        </div>
        <div class = "content">
          <div class = "title" v-html="translate(item.title)"></div>
          <div class = "desc" v-html="translate(item.desc)"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang='scss'>
@import '../sass/UtilityClasses';
@import '../sass/Colors';
@import "../sass/Settings";

.InstructionsWrapper {
  background-color: #1f793d;
  z-index: 1;

  .ribbon {
    position: absolute;
    width: 100%;
    left: 0;
    height: auto;
    z-index: 1;
  }
  .ribbon-top {
    top: 0;
  }
  .ribbon-bottom {
    bottom: vw(15);
  }

  .title-area {
    position: absolute;
    top: vh(16);
    left: vh(16);
    height: vh(48);
    display: flex;
    flex-direction: row;
    align-items: center;

    .header-text {
      font-family: Bogle;
      font-size: vh(28);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      position: relative;
      top: vh(4);
    }
    .back-button {
      width: vh(48);
      margin-right: vh(20);
    }
  }

  .instruction-list {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    padding-left: vw(16);
    padding-right: vw(16);
    padding-top: vh(77);
    padding-bottom: vh(40);

    .instruction-item {
      width: vw(343);
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: vh(12);
      box-sizing: border-box;
      flex-grow: 1;
      border-radius: vh(20);
      background-image: linear-gradient(90deg, rgb(0, 113, 220) 0%, #0071dc 100%);
      overflow: hidden;

      *{
        box-sizing: border-box;

      }

      .display-image-hider {
        position: relative;
        width: vw(122);
        left: 0;
        height: 100%;
        margin-right: vw(18);
        overflow: hidden;
      }
      .display-image {
        position: absolute;
        height: 101%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      @media screen and (min-aspect-ratio: 462/826) {
        .display-image {
          left: 0;
          transform: translateY(-50%);
        }
      }

      .content{
        position: relative;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: vw(190);

        .title{
          font-family: Bogle;
          font-size: vw(20);
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.1;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }

        .desc{
          margin-top: vh(8);
          font-family: Bogle;
          font-size: vw(18);
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          text-align: left;
          color: #fff;
        }

      }
    }
    .instruction-item:nth-child(1) {
      background-image: linear-gradient(90deg, #5FB1E7 0%, #3785DC 100%);
    }
    .instruction-item:nth-child(2) {
      background-image: linear-gradient(90deg, #1571E9 0%, #1364E5 100%);
    }
    .instruction-item:nth-child(3) {
      background-image: linear-gradient(90deg, #082EAC 0%, #0724A5 100%);
    }
  }

}

</style>
