import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import {deviceDetectionStore} from "./device-detection";
import {appStateStore} from "./app-state";
import {failPermissionsStore} from "./fail-permissions";

export const store = {
  state: {
    ...state,
    ...deviceDetectionStore.state,
    ...appStateStore.state,
    ...failPermissionsStore.state
  },
  getters: {
    ...getters,
    ...deviceDetectionStore.getters,
    ...appStateStore.getters,
    ...failPermissionsStore.getters
  },
  mutations: {
    ...mutations,
    ...deviceDetectionStore.mutations,
    ...appStateStore.mutations,
    ...failPermissionsStore.mutations
  },
  actions: {
    ...actions,
    ...deviceDetectionStore.actions,
    ...appStateStore.actions,
    ...failPermissionsStore.actions
  },
};
