<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import audioControl from "../js/audioControl.js";
import { useStore } from "vuex";

import AR from "@/js/playcanvasAR.js";


import { inject } from 'vue'
import analytics from "@/js/analytics";

var ProgressBar = require('progressbar.js');

const translate = inject('translate')
// grab state
const store = useStore();

let promptVisible = ref(false);
let text0Visible = ref(true);
let text1Visible = ref(false);

let buyPrompt = ref(false);

onMounted(async () => {
  var gradient = '<defs><linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse"><stop offset="5%" stop-color="#8DBFF6" /><stop offset="30%" stop-color="#5FA1EE" /><stop offset="70%" stop-color="#215DBF" /><stop offset="95%" stop-color="#1B4DA5" /></linearGradient></defs>';

  var progressBarContainer = document.getElementsByClassName('progress-bar-container')[0];
  progressBarContainer.innerHTML = '';
  var bar = new ProgressBar.SemiCircle(progressBarContainer, {
    color: "url(#gradient)",
    strokeWidth: 8,
    trailWidth: 10,
    easing: 'easeInOut',
    svgStyle: {
      width: '100%',
      height: '100%',
      strokeLinecap: 'round',
    }
  });

  var pulsingValue = 0; // Track the pulsing animation value
  var shakeValue = 0; // Track the shake value

  // Function to animate the pulse
  function pulseAnimation() {
    // Animate pulsing between 0 and 0.1
    pulsingValue = pulsingValue === 0.001 ? 0.1 : 0.001;
    //let combinedValue = Math.max(pulsingValue, shakeValue);
    let combinedValue = pulsingValue + shakeValue;
    bar.animate(combinedValue, { duration: 500 });

    // Loop the pulse
    setTimeout(pulseAnimation, 250);
  }

  // Start pulsing animation initially
  pulseAnimation();

  AR.app.on("box-opened", function () {
   buyPrompt.value = true;
  });

  AR.app.on("reveal", function () {
    analytics.changePage("Reveal");
    analytics.analyticsMethod("openSuccess");
    analytics.changePage("Carousel");
  });

  AR.app.on("shop", function (category, product, url) {
    analytics.analyticsMethod("shop_" + category + "_" + product, "shop", product, url);
  });

  setTimeout(function () {
    promptVisible.value = true;
  }, 200);

  bar.svg.insertAdjacentHTML('afterbegin', gradient);

  store.commit('setShakeAmount', 0);
  const shakeAmount = computed(() => store.getters.getShakeAmount);

  watch(shakeAmount, (newVal, oldVal) => {
    console.log(`shakeAmount changed from ${oldVal} to ${newVal}`);

    shakeValue = newVal;
    //let combinedValue = Math.max(pulsingValue, shakeValue);
    let combinedValue = pulsingValue + shakeValue;
    bar.animate(combinedValue, {duration: 500});

    if (newVal > 0.5) {
      text0Visible.value = false;
      text1Visible.value = true;
    }
    if(newVal === 1){
      promptVisible.value = false;
      text1Visible.value = false;
    }
  });
});

onUnmounted(async () => {

});

const restart = () => {
  audioControl.restartAudio("guiclick");
  AR.app.fire("change-state", "place");
  analytics.analyticsMethod("resetAR");
};

const instructions = () => {
  audioControl.restartAudio("guiclick");
  store.commit("instructionOpen", true);
  analytics.analyticsMethod("openHelp");
};


</script>

<template>
  <div class="ar-container absolute-fill" id="ar">

    <!-- All top anchored buttons -->
    <transition name="fade">
      <div class="top-icons">
        <!-- Share button & Share hint -->
        <div class="button-instructions" ontouchstart="" v-on:click="instructions">
          <UncopyableImage
            src="../www/assets/imgs/icon-instruction.png"
            width="40"
            height="40"
          />
        </div>
      </div>
    </transition>
    <!--
    <transition name="scale">
      <img v-if="buyPrompt" src="../../public/assets/imgs/tap-to-reveal.png" class="place-hand">
    </transition>
    -->

    <!-- Shake prompt -->
    <transition name="scale">
    <div class="prompt-container" v-show="promptVisible">
      <div class="progress-bar-container">
      </div>
      <img src="../../public/assets/imgs/shake-device-loop.png" class="shake-hand">
    </div>
    </transition>


    <!-- All bottom anchored buttons -->
      <div class="bottom-icons">
        <div class="instructions-container">
          <div class="shake-text-0 looped-shake-animation" v-if="text0Visible">
            <div class="shake-text medium-font-weight"
                v-html="translate('instructions-shake-0')"/>
            <div class="shake-text"
                v-html="translate('instructions-shake-1')"/>
          </div>
          <div class="shake-text-1 looped-shake-animation" v-if="text1Visible">
            <div class="shake-text medium-font-weight"
                 v-html="translate('instructions-keep-shaking')"/>
          </div>
          <transition name="fade">
          <div class="shake-text-0" v-if="buyPrompt">
            <div class="shake-text medium-font-weight"
                 v-html="translate('instructions-buy-part-1')"/>
            <div class="shake-text"
                 v-html="translate('instructions-buy-part-2')"/>
          </div>
          </transition>
        </div>


        <!-- Restart button & Restart hint -->
        <div class="button-restart" ontouchstart="" v-on:click="restart">
          <UncopyableImage
            src="../www/assets/imgs/icon-restart.png"
            width="30"
            height="34"
          />
        </div>
      </div>



  </div>
</template>


<style scoped lang='scss'>
@import '../sass/Settings';
@import '../sass/Colors';
@import '../sass/GlobalClasses';

.ar-container {
  pointer-events: none;
  z-index: 2;

  * {
    pointer-events: initial;
  }


  .button-instructions {
    position: absolute;
    top: 5.6vw;
    right: 5.6vw;
    width: fit-content;
  }

  .place-hand
  {
    position: absolute;
    top: 17%;
    left: 50%;
    transform: scale(1) translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: vw(137);
  }

  .bottom-icons {
    position: absolute;
    bottom: vw(36);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    left: vw(21);
    right: vw(21);

    .button-share {
      position: relative;

      .onboarding {
        position: absolute;
        left: 0;
        top: vw(-37);
      }
    }

    .button-restart {
      position: relative;

      .onboarding {
        position: absolute;
        right: 0;
        top: vw(-37);
      }
    }

    .button-camera {
      position: relative;
      margin-left: vw(15);

      .onboarding {
        position: absolute;
        top: vw(-39);
        left: vw(-18);
      }
    }

    .instructions-container {
      position: relative;
      margin: auto 0;

      .shake-text {
        text-shadow: 0 0 vw(10) rgba(0, 0, 0, 0.61);
        font-family: Bogle;
        font-size: vw(21);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
        white-space: nowrap;
      }
      .medium-font-weight {
        font-weight: 500;
        font-size: vw(36);
      }
    }

    .placeholder {
      width: vw(40);
      height: vw(35);
    }
  }

  .prompt-container {
    position: absolute;
    width: vw(237);
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    pointer-events: none;
  }

  .progress-bar-container {
    width: vw(182);
    height: vw(110);
    pointer-events: none;
  }

  .shake-hand {
    top: vw(45);
    position: absolute;
    width: vw(160);
    aspect-ratio: 1;
    pointer-events: none;
  }

  .place-hand {
    margin-bottom: vw(47);
  }

  .place-text {
    @include box-sizing;
    text-shadow: vw(1.3) vw(1.3) 0 rgba(0, 1, 1, 0.16);
    font-family: Roboto;
    font-size: fontVW(28);
    font-weight: 300;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #FFFFFF;
  }

  .faded-background {
    pointer-events: auto;
    background-color: $faded-camera;
  }

  .fades-in {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .fades-in.visible {
    opacity: 1;
  }
}


[v-cloak] {
  display: none;
}

</style>