// Figured I would put all the pga specific store variables in one place

export const deviceDetectionStore = {
  state: {
    isChrome: false,
    isAndroid: false,
    isMobileSafari: false,
    isMobile: false,
    isIOS: false,
    isTablet: false,
    UA: "",
    iOSMajorVersion: 13,
    iOSMinorVersion: 1,
    iOSVersionCutoff: 12,
    isIPhoneX: false,
    isBrowserSupported: false,
    isIGBrowser: false,
    isFBBrowser: false,
  },
  getters: {
    getIsChrome: (state) => {
      return state.isChrome;
    },
    getIsAndroid: (state) => {
      return state.isAndroid;
    },
    getIsMobileSafari: (state) => {
      return state.isMobileSafari;
    },
    getIsMobile: (state) => {
      return state.isMobile;
    },
    getIsIOS: (state) => {
      return state.isIOS;
    },
    getIsTablet: (state) => {
      return state.isTablet;
    },
    getUA: (state) => {
      return state.UA;
    },
    getHasDeviceTest: (state) => !!state.UA,
    getIOSMajorVersion: (state) => {
      return state.iOSMajorVersion;
    },
    getIOSMinorVersion: (state) => {
      return state.iOSMinorVersion;
    },
    getIOSVersionCutoff: (state) => {
      return state.iOSVersionCutoff;
    },
    getIsIphoneX: (state) => {
      return state.isIPhoneX;
    },
    getIsBrowserSupported: (state) => {
      return state.isBrowserSupported;
    },
    isIGBrowser: (state) => {
      return state.isIGBrowser;
    },
    isFBBrowser: (state) => {
      return state.isFBBrowser;
    },
  },
  mutations: {
    setIsChrome(state, isChrome) {
      state.isChrome = isChrome;
    },
    setIsAndroid(state, isAndroid) {
      state.isAndroid = isAndroid;
    },
    setIsMobileSafari(state, isMobileSafari) {
      state.isMobileSafari = isMobileSafari;
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    setIsIOS(state, isIOS) {
      state.isIOS = isIOS;
    },
    setIsTablet(state, isTablet) {
      state.isTablet = isTablet;
    },
    setUA(state, ua) {
      state.UA = ua;
    },
    setIOSMajorVersion(state, target) {
      state.iOSMajorVersion = target;
    },
    setIOSMinorVersion(state, target) {
      state.iOSMinorVersion = target;
    },
    setIOSVersionCutoff(state, target) {
      state.iOSVersionCutoff = target;
    },
    setIsIphoneX(state, target) {
      state.isIPhoneX = target;
    },
    setIsBrowserSupported(state, target) {
      state.isBrowserSupported = target;
    },
    isIGBrowser(state, target) {
      state.isIGBrowser = target;
    },
    isFBBrowser(state, target) {
      state.isFBBrowser = target;
    },
  },
  actions: {

  },
};
