<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { inject } from 'vue'
import audioControl from "@/js/audioControl";
import analytics from "@/js/analytics";
const translate = inject('translate')
const vw = inject('vw');

// grab store
const store = useStore();

const hasOpened = ref(false);

// Computed props
const isActive = computed(() => store.getters.getLegalFooterActive);
const isRotated = computed(() => store.getters.isRotated);

const toggleActive = (val) => {
  hasOpened.value = true;
  store.commit(
    "setLegalFooterActive",
    val !== undefined ? val : !isActive.value
  );
  audioControl.restartAudio("guiclick");
  if (isActive.value){
    analytics.analyticsMethod("openLegal");
  }else{
    analytics.analyticsMethod("closeLegal");
  }

};
const getLink = (textKey, linkKey) => {
  var text = translate(textKey);
  var link = translate(linkKey);

  return `<a href='${link}' target="_blank">${text}</a>`;
};

const getIsDesktop = computed(() => {
  return !store.getters.getIsMobile && !store.getters.getIsTablet;
});
</script>

<template>
  <div class="popup-wrapper" :class="{'is-desktop': getIsDesktop}">
    <div class="lockup-wrapper" v-if="!isRotated" />
    <div
      v-if="!isRotated"
      class="popup-legal-footer"
      :class="{
        isActive: isActive,
      }"
    >
      <div
        class="legal-footer-main"
        :class="{ isActive: isActive || getIsDesktop }"
      >
        <div
          class="mainContentArea"
          :class="{ isActive: isActive || getIsDesktop }"
        >
          <div class="hiderArea" @click="toggleActive()">
            <div class="hiderAreacontent">
              <span class="barText">Legal</span>
              <div class="btnClose-container">
                  <img v-if="isActive" class="btnClose"
                    src="../../../public/assets/imgs/footer-close-expand-btn.png"
                  />
                  <img v-if="!isActive" class="btnOpen"
                    src="../../../public/assets/imgs/footer-close-expand-btn.png"
                  />
              </div>
            </div>
          </div>
          <div class="mainContent">
            <div class="mainContentWrapper">
              <img class="wm-logo" src="../../../public/assets/imgs/wm-logo.png"/>
              <img class="eth-wall-logo" src="../../../public/assets/imgs/8-thwall-logo.png"/>
              <div class="legal-text">
                <span v-html="translate('legal-footer-1')"></span>
                <a v-bind:href="translate('terms-conditions-url')">
                  <span class="link" v-html="translate('terms-conditions-text')"></span>
                </a>
                <span v-html="translate('legal-footer-2')"></span>
                <a v-bind:href="translate('privacy-policy-url')">
                  <span class="link" v-html="translate('privacy-policy-text')"></span>
                </a>
                <span v-html="translate('legal-footer-3')"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../sass/Settings";
@import "../../sass/Colors";

.popup-wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  .lockup-wrapper {
    position: relative;
    top: -30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .popup-legal-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 11;
    pointer-events: all;
    transform: translateY(91%);
    transition: transform 0.25s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.loaded {
      transform: translateY(90%);
    }
    &.isActive {
      transform: translateY(0);
    }
    &.appFailed {
      z-index: 11;
      .legal-footer-persistent {
        background-color: #fff;
      }
      .legal-footer-main {
        background-color: #fff;
        .mainContentArea {
          background-color: #fff;
          .mainContent {
            background-color: #fff;
          }
        }
      }
    }
    .landing-content-sub-second {
      position: relative;
      bottom: 10px;
    }
    .legal-footer-main {
      transition: all 0.25s ease-in-out 0s;
      height: 100%;

      width: 100%;
      background-color: white;

      &.isActive {
        height: 100%;
      }

      .mainContentArea {
        width: 100%;
        top: 0;
        height: 100%;
        #teconsent {
          margin-right: 5px;
          background: transparent !important;
        }
        .hiderArea {
          position: relative;
          height: vw($legal-footer-closed-height);
          width: 100%;
          pointer-events: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #0071dc;
          border-bottom: solid 1px #fff;
          .hiderAreacontent {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .btnClose-container {
              display: flex;
              align-items: center;
              padding-bottom: 2px;
              .btnOpen,
              .btnClose {
                height: 5px;
                width: 9px;
                .bg-image {
                }
              }
            }
          }
        }

        .mainContent {
          position: relative;
          width: 100%;
          height: 100%;
          background-color: #0071dc;
          display: flex;
          flex-direction: column;
          align-items: center;
          .mainContentWrapper {
            width: vw(330);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .wm-logo {
              width: vw(111);
              height: vw(26);
              margin: vw(20) 0 vw(20) 0;
              align-self: flex-start;
            }

            .eth-wall-logo {
              position: absolute;
              width: vw(100);
              height: vw(19);
              margin: vw(20) vw(20) 0 0;
              align-self: flex-end;
            }

            .legal-text {
              margin: 0 0 vw(32) 0;
              font-family: Bogle, sans-serif;
              font-size: vw(11);
              font-weight: vw(500);
              font-stretch: normal;
              font-style: normal;
              line-height: vw(18);
              letter-spacing: vw(0.11);
              text-align: left;
              color: #fff;

              .link {
                font-weight: bold;
                color: #ffc220;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    &,
    * {
      box-sizing: border-box;
    }
    .legal-footer-persistent {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;

      height: vw(88);
      background-color: #fff;

      .barText {
        font-family: Bogle, sans-serif;
        font-weight: bold;
        font-size: 11px;
        text-align: center;
        color: white;
        margin-right: 5px;
        margin-bottom: 1px;
        box-sizing: inherit;
        padding-bottom: 0.5px;
      }
    }
    .barText {
      padding-bottom: 0.5px;
      font-family: Bogle, sans-serif;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      text-align: center;
      margin-right: 5px;
      color: white;
      box-sizing: inherit;
    }
    .btnOpen,
    .btnClose {
      transition: none !important;
      pointer-events: auto;
      // position: relative;
      // bottom: 2.7px;
      margin-right: 5px;
      display: flex;
      align-items: flex-end;

    }
    .btnClose {
      transform: rotate(0deg);
    }
    .btnOpen {
      transform: rotate(180deg);
    }
  }
}
.linkOutArea.policies {
  flex-direction: row !important;
  justify-content: flex-start !important;
  margin: 15px 0 5px 0 !important;
  padding-top: 0 !important;

  font-family: HelveticaNeue-Roman, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  span {
    &:first-of-type {
      margin-right: 30px;
    }
  }
}
.linkOutArea.calories {
  width: 90% !important;
  padding-top: 0 !important;
  margin-bottom: 3px;
  span {
    font-family: HelveticaNeue-Roman, sans-serif;
    font-size: 9px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.78;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
}

.popup-wrapper.is-desktop {
  //z-index: 200;

  .popup-legal-footer {
    transform: translateY(90%);
    &.loaded {
      transform: translateY(90%);
    }
    &.isActive {
      transform: translateY(0);
    }
  }

  .mainContentArea {
    .hiderArea {
      height: vw(20, $design-width-desktop) !important;
    }
  }

  .mainContentWrapper {
    //width: vw(657, $design-width-desktop) !important;
    width: 100% !important;
    align-self: start;

    .wm-logo {
      width: vw(111, $design-width-desktop) !important;
      height: vw(26, $design-width-desktop) !important;
      margin: vw(20, $design-width-desktop) 0 vw(20, $design-width-desktop) vw(20, $design-width-desktop) !important;
    }

    .eth-wall-logo {
      width: vw(136, $design-width-desktop) !important;
      height: vw(26, $design-width-desktop) !important;
      margin: vw(20, $design-width-desktop) vw(20, $design-width-desktop) 0 0 !important;
    }

    .legal-text {
      align-self: start;
      width: vw(657, $design-width-desktop) !important;
      font-size: vw(11, $design-width-desktop) !important;
      letter-spacing: vw(0.1, $design-width-desktop) !important;
      line-height: vw(20, $design-width-desktop) !important;
      margin: 0 0 vw(32, $design-width-desktop) vw(20, $design-width-desktop) !important;
    }

    .accessibility-icon {
      width: vw(16, $design-width-desktop) !important;
      top: 0.1vw !important;
      margin-right: 0.1vw !important;
    }

    .copyright {
      margin-top: vw(24, $design-width-desktop) !important;
      margin-bottom: vw(11, $design-width-desktop) !important;
    }

    .disclaimer {

      margin-top: vw(23, $design-width-desktop) !important;
      margin-bottom: vw(23, $design-width-desktop) !important;
    }

    .links {
      font-size: vw(10, $design-width-desktop) !important;
      letter-spacing: 0.1vw !important;
      width: vw(600, $design-width-desktop);
    }
  }
}
</style>
