<script setup>
import { ref } from "vue";
import AR from "@/js/playcanvasAR";


const events = ref([
  {
    display: "Fix Loading on Desktop",
    value: "xr:realityready",
  },
]);
const sendEvent = (value) => {
  AR.app.fire(value);
};
</script>

<template>
  <div class="module-container">
    <div class="debug-screen-area">
      <div
          class="screen-button"
          v-for="event in events"
          v-bind:key="event"
          v-on:click="sendEvent(event.value)"
      >
        <div>
          {{ event.display }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../sass/UtilityClasses';
@import '../../sass/Colors';
@import '../../sass/Settings';


.module-container {
  @extend %absolute-fill;

  .debug-screen-area {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;

    background-color: rgba(0, 0, 0, 0.3);

    padding-top: 4vw;
    padding-bottom: 4vw;

    .screen-button {
      font-size: 5vw;
      color: black;

      margin-bottom: 3vw;

      display: flex;
      justify-content: center;
      align-items: center;
      height: 6vw;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding-left: 2vw;
      padding-right: 2vw;

      position: relative;

      background-color: white;
      pointer-events: auto;
    }
  }
}
</style>
