<script setup>
import { ref, onMounted, computed } from "vue";
const frameRate = ref(0);
const lastTime = ref(0);
const times = ref([0, 0, 0, 0, 0]);

onMounted(() => {
  probeFrame();
});

const probeFrame = () => {
  window.requestAnimationFrame(() => {
    let now = performance.now();
    times.value.push(now - lastTime.value);
    times.value.shift();
    frameRate.value =
      1000 /
      (times.value.reduce((a, b) => {
        return a + b;
      }) /
        times.value.length);
    frameRate.value = frameRate.value.toFixed(2);
    lastTime.value = now;
    probeFrame();
  });
};

const getFrameRate = computed(() => {
  return "FRAME RATE: " + frameRate.value;
});
</script>

<template>
  <div class="module-container">
    <div class="frame-rate-parent">
      <div class="background" />
      <div class="frame-rate-text">
        {{ getFrameRate }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../sass/UtilityClasses';
@import '../../sass/Colors';
@import '../../sass/Settings';


.module-container {
  @extend %absolute-fill;

  .frame-rate-parent {
    width: 100%;
    height: 15%;
    position: relative;
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

    .background {
      @extend %absolute-fill;
      background-color: black;
      opacity: 0.5;
    }

    .frame-rate-text {
      width: 30%;
      text-align: center;
      color: white;
    }
  }
}
</style>
