<script setup>
import audioControl from "@/js/audioControl";

const store = useStore();
import {onUnmounted, ref, onMounted, computed, inject} from "vue";
import {useStore} from "vuex";
import Debug from "../components/debug_modules/DebugMenu.vue";
import analytics from "@/js/analytics";
import loadSequence from "@/js/loadSequence";
import AR from "@/js/playcanvasAR.js";
const timeout = ref(null);
const loading = ref(false);
const loaded = ref(false);

const translate = inject('translate')

const isPermissionFinished = computed(() => store.getters.isPermissionFinished);
const isIOS = computed(() => store.getters.getIsIOS);

onUnmounted(() => clearTimeout(timeout.value));

let visibleElements = ref(12);
let incrementVisibleElements = function () {
  visibleElements.value++;
}

onMounted(() => {
  analytics.changePage("Landing");

  visibleElements.value = 0;

  setTimeout(incrementVisibleElements, 200)
  setTimeout(incrementVisibleElements, 400)
  setTimeout(incrementVisibleElements, 600)
  setTimeout(incrementVisibleElements, 800)
  setTimeout(incrementVisibleElements, 1000)
});

let blurOn = ref(false);
let showButtons = ref(true);

async function handleButton(buttonIdx) {
  audioControl.restartAudio("guiclick");
  console.log("Button pressed: " + buttonIdx);
  store.commit("setSelectedGift", buttonIdx);
  blurOn.value = true;
  showButtons.value = false;
  const buttonName = ['Kid', 'Fashion', 'Gaming'][buttonIdx];
  analytics.analyticsMethod("startAR_" + buttonName);
  analytics.changePage("Loading");
  await doPermissions();
  store.commit("setIsLanding", false);
  analytics.analyticsMethod("loadComplete");
  analytics.changePage("Tap to Place");
  AR.app.on("place-tap", function () {
    analytics.analyticsMethod("placeAR");
    analytics.changePage("Shake Phone");
  });
}

async function doPermissions() {
  if (!loading.value) {
    store.dispatch("legalFooterActive", false);
    loading.value = true;
    store.commit("reset");
    store.commit("loadStarted");

    await loadSequence.asyncPermissions();

    store.commit("isPermissionFinished", true);
    blurOn.value = false;

    await loadSequence.asyncLoad();

    loading.value = false;
    loaded.value = true;
  }
}

</script>

<template>
  <div class="landing-page">
    <img class = "logo" src="../../public/assets/imgs/8-thwall-logo.png" v-if="!showButtons && !isPermissionFinished"/>
    <div class="background-image-container blurred" :class="{'blur-on': blurOn}">
      <img class = "background-image-background" src="../../public/assets/imgs/landing-bg.png"/>
      <img class = "background-image" src="../../public/assets/imgs/box-shake-4.png"/>
      <!--<img class = "box-shadow" src="../../public/assets/imgs/box-shadow.png"/>-->
      <!--<img class = "landing-ribbon-1" src="../../public/assets/imgs/landing-ribbon-1.png"/>-->
      <!--<img class = "landing-ribbon-2" src="../../public/assets/imgs/landing-ribbon-2.png"/>-->
    </div>
    <div class="landing-page-section">


      <div class="title-container fades-in" :class="{visible: visibleElements >= 1 && showButtons}">
        <div class = "title-text header-text fades-in" :class="{visible: visibleElements >= 2}" v-html="translate('landing-cta')"></div>
        <div class = "button fades-in" :class="{visible: visibleElements >= 3 && showButtons}" @click.once="handleButton(0)">
          <div class = "text" v-html="translate('landing-btn-1')" :class="{isIOS}"></div>
        </div>
        <div class = "button fades-in" :class="{visible: visibleElements >= 4 && showButtons}" @click.once="handleButton(1)">
          <div class = "text" v-html="translate('landing-btn-2')" :class="{isIOS}"></div>
        </div>
        <div class = "button fades-in" :class="{visible: visibleElements >= 5 && showButtons}" @click.once="handleButton(2)">
          <div class = "text" v-html="translate('landing-btn-3')" :class="{isIOS}"></div>
        </div>
      </div>

      <div class="prompt-container fades-in" :class="{visible: !showButtons && !isPermissionFinished}">
        <img class = "prompt-icon" src="../../public/assets/imgs/icon-camera.png"/>
        <div class = "prompt-text header-text" v-html="translate('motion-and-camera-header')"></div>
      </div>

      <div class="loading-container fades-in" :class="{visible: isPermissionFinished && loading && !loaded}">
        <span class="loader"></span>
        <div class="loading-text" v-html="translate('loading-title')"></div>
      </div>

    </div>
    <Debug />
  </div>
</template>

<style scoped lang='scss'>
@import '../sass/Settings';
@import '../sass/Colors';
@import '../sass/GlobalClasses';

.landing-page {
  overflow: hidden;
  height: 100vh;
  background-color: #B8ADA1;

  .logo {
    position: absolute;
    bottom: vw(40);
    left: 50%;
    transform: translateX(-50%);
    width: vw(126);
    z-index: 2;
  }
  .background-image-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .background-image {
      position: absolute;
      bottom: 10vw;
      width: 100%;
      height: auto;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
    }
    .box-shadow {
      position: absolute;
      bottom: 15vw;
      width: 100%;
      height: auto;
      left: 50%;
      transform: translateX(-50%);
    }
    .background-image-background {
      width: 200%;
      height: auto;
      //height: 120%;
      //width: auto;
      position: absolute;
      //top: 0%;
      bottom: -12%;
      left: 50%;
      transform: translate(-50%, 0%);
    }
    .landing-ribbon-1 {
      position: absolute;
      right: 0;
      bottom: vw(249);
      width: vw(119);
    }
    .landing-ribbon-2 {
      position: absolute;
      left: 0;
      bottom: 6vw;
      z-index: 1;
      width: vw(212);
    }
  }

  .landing-page-section {
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .title-container {
    margin-top: vh(28);
    margin-left: vh(28);
    position: relative;

    .title-text {
      width: vw(300);
      font-family: Bogle;
      font-size: vw(40);
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.05;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      margin-bottom: vh(4);

      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    }

    .button {
      position: relative;
      width: vw(187);
      margin-top: vh(12);
      height: vw(50);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      border-radius: vw(36);
      background-color: #0071dc;

      .text {
        font-family: Bogle;
        font-size: vw(16);
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        position: relative;
        top: vw(4);
      }
      .text.isIOS {
        top: 0;
      }
    }

    .button:active {
      background-color: #FFFFFF;
      .text {
        color: #0071dc;
      }
    }
  }

  .prompt-container {
    position: absolute;
    top: 0;
    margin-top: vw(25);
    margin-left: vw(40);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: -1;

    .prompt-icon {
      width: vw(56);
      height: auto;
    }
    .prompt-text {
      width: vw(290);
      margin-top: vw(10);
      font-family: Bogle;
      font-size: vw(28);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
  }

  .loading-container {
    position: absolute;
    width: 100vw;
    top: 60%;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;

      .loader {
        width: vh(64);
        height: vh(64);
        border: vh(3) solid #fff;
        border-bottom-color: #ffc220;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
      }

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

    .loading-text {
      margin-top: vh(20);
      font-family: Bogle;
      font-size: vh(26);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.04;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }

  .blurred {

  }
  .blurred.blur-on {
    animation: fadeBlur 1s forwards;
  }

  .fades-in {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .fades-in.visible {
    opacity: 1;
  }

  @keyframes fadeBlur {
    0%
    {
      filter: blur(0px);
    }
    100%
    {
      filter: blur(10px);
    }
  }


}

</style>
