<script setup>
import PlacingWrapper from "./PlacingWrapper.vue";
import MainContentWrapper from "@/views/MainContentWrapper.vue";

import {computed} from "vue";
import {useStore} from "vuex";
import InstructionsPage from "@/components/InstructionsPage.vue";
import audioControl from "@/js/audioControl";

const store = useStore();

const playCanvasState = computed(() => store.getters.getPlayCanvasState);

const loaded = computed(() => store.getters.getLoaded);
const isFlashing = computed(() => store.getters.getFlashing);

const getAppFailed = computed(() => store.getters.getAppFailed);

const instructionOpen = computed(() => store.getters.instructionOpen);
const closeInstructions = () => {
  audioControl.restartAudio("guiclick");
  store.commit("instructionOpen", false);
};
</script>

<template>
  <div
      style = "z-index: 2; pointer-events: none"
      class = "absolute-fill main-wrapper"
  >
    <transition name = "fade">
      <MainContentWrapper v-if = "playCanvasState === 'content'" />
    </transition>
    <transition name = "fade">
      <PlacingWrapper :visible = "playCanvasState === 'place'" />
    </transition>
    <transition name="fade">
      <InstructionsPage v-if = "instructionOpen" @close="closeInstructions" style="z-index: 10" :loaded="true"/>
    </transition>
    <!--
    <transition name = "camera-flash">
      <div v-if = "isFlashing" class = "camera-flash" />
    </transition>
    <transition name = "fade">
      <LoadingWrapper v-if = "!loaded && !getAppFailed" />
    </transition>
    -->
    <transition name = "fade">
      <Fail v-cloak v-if = "getAppFailed" />
    </transition>

  </div>
</template>
