// Figured I would put all the pga specific store variables in one place

export const appStateStore = {
  state: {
    playCanvasState: "",
    legalFooterActive: false,
    firstTimeRun: true,
    isLanding: true,
    loadStarted: false,
    loaded: false,
    scanned: false,
    shareOpen: false,
    instructionOpen: false,
    isOnboarding: false,
    flash: false,
    photoOpen: false,
    selectedGift: 0,
    shakeAmount: 0,
  },
  getters: {
    getPlayCanvasState(state) {
      return state.playCanvasState;
    },
    getLegalFooterActive(state) {
      return state.legalFooterActive;
    },
    firstTimeRun: (state) => {
      return state.firstTimeRun;
    },
    getIsLanding: (state) => {
      return state.isLanding;
    },
    getLoadStarted: (state) => {
      return state.loadStarted;
    },
    getLoaded: (state) => {
      return state.loaded;
    },
    getScanned: (state) => {
      return state.scanned;
    },
    getShareOpen: (state) => {
      return state.shareOpen;
    },
    instructionOpen: (state) => {
      return state.instructionOpen;
    },
    getIsOnboarding: (state) => {
      return state.isOnboarding;
    },
    getFlashing: (state) => {
      return state.flash;
    },
    getPhotoOpen: (state) => {
      return state.photoOpen;
    },
    getSelectedGift: (state) =>{
      return state.selectedGift;
    },
    getShakeAmount: (state) =>{
      return state.shakeAmount;
    }
  },
  mutations: {
    setPlayCanvasState(state, target) {
      //NOTE: Don't change this directly. Use this.app.fire("change-state") instead.
      state.playCanvasState = target;
    },
    setLegalFooterActive(state, target) {
      state.legalFooterActive = target;
    },
    setFirstTimeRun(state, target) {
      state.firstTimeRun = target;
    },
    setIsLanding: (state, target) => {
      state.isLanding = target;
    },
    loadStarted(state) {
      state.loadStarted = true;
    },
    loadComplete(state) {
      // mutate state
      state.loaded = true;
    },
    setScanned(state, isScanned) {
      state.scanned = isScanned;
    },
    setShareOpen(state, isShareOpen) {
      state.shareOpen = isShareOpen;
    },
    instructionOpen(state, target) {
      state.instructionOpen = target;
    },
    setIsOnboarding(state, target) {
      state.isOnboarding = target;
    },
    setFlashing(state, isFlashing) {
      state.flash = isFlashing;
    },
    setPhotoOpen(state, isPhotoOpen) {
      state.photoOpen = isPhotoOpen;
    },
    setSelectedGift(state, selectedGift) {
      state.selectedGift = selectedGift;
    },
    setShakeAmount(state, shakeAmount) {
      state.shakeAmount = shakeAmount;
    }
  },
  actions: {
    legalFooterActive({ commit }, target) {
      commit("setLegalFooterActive", target);
    },
  },
};
