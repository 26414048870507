<script setup>
import {onMounted, ref, watch} from "vue";
import audioControl from "../js/audioControl.js";
import { inject } from 'vue'
import {useStore} from "vuex";
import analytics from "@/js/analytics";
import AR from "@/js/playcanvasAR.js";
const translate = inject('translate')
const store = useStore();

const revealActive = ref(false);
const part = ref(0);

const props = defineProps({
  visible: {default: false, type: Boolean}
});

watch(() => props.visible, (newValue, oldValue) => {
  if (newValue === true)
  {

    part.value = 0;
    setTimeout(function () {
      part.value = 1;
    }, 2000)
  }
});

onMounted(() => {
  //analytics.changePage("Tap to Place");

  audioControl.startListeners();
  setTimeout(function () {
    revealActive.value = true;
  }, 100)

  part.value = 0;
  setTimeout(function () {
    part.value = 1;
  }, 2000);
  /*
  AR.app.on("place-tap", function () {
    analytics.analyticsMethod("placeAR");
  });
   */

});

const instructions = () => {
  audioControl.restartAudio("guiclick");
  store.commit("instructionOpen", true);
  analytics.analyticsMethod("openHelp");
};

</script>

<template>
  <div class="place-container absolute-fill" >

    <!-- I hate that I had to do the transitions this way but nothing else worked :(-->
    <transition name="fade">
      <div class="button-instructions" ontouchstart="" v-on:click="instructions" v-if="visible">
        <UncopyableImage
            src="../www/assets/imgs/icon-instruction.png"
            width="40"
            height="40"
        />
      </div>
    </transition>
    <div class="place-hand-indicator">
      <transition name="scale">
        <img v-if="visible" src="../../public/assets/imgs/tap-to-reveal.png" class="place-hand">
      </transition>
      <transition name="fade">
        <div class="place-text-container" v-if="visible && part==0">
          <div
              class="place-text medium-font-weight"
              v-html="translate('instructions-place-part-1')"
          />
          <div
              class="place-text"
              v-html="translate('instructions-place-part-2')"
          />
        </div>
      </transition>
      <transition name="fade">
        <div class="place-text-container looped-tap-animation" v-if="visible && part==1">
          <div
              class="place-text medium-font-weight"
              v-html="translate('instructions-place-0')"
          />
          <div
              class="place-text"
              v-html="translate('instructions-place-1')"
          />
        </div>
      </transition>

    </div>
  </div>
</template>


<style scoped lang='scss'>
@import '../sass/Settings';
@import '../sass/Colors';
@import '../sass/GlobalClasses';


.place-container
{

  z-index: 1;

  .button-instructions {
    position: absolute;
    top: 5.6vw;
    right: 5.6vw;
    width: fit-content;
  }

  .place-hand
  {
    position: absolute;
    top: 17%;
    left: 50%;
    transform: scale(1) translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: vw(137);
  }

  .place-text-container {

    position: absolute;
    left: vw(28);
    bottom: vw(36);

    .place-text
    {
      text-shadow: 0 0 vw(10) rgba(0, 0, 0, 0.61);
      font-family: Bogle;
      font-size: vw(21);
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    .medium-font-weight {
      font-weight: 500;
      font-size: vw(36);
    }
  }

}


</style>